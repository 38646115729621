var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('div',[(_vm.canRequest && !_vm.checkingAccess)?[_c('admin-title',{staticClass:"mb-n5",attrs:{"title":"Requests"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(
            _vm.user.role == 'admin' ||
            _vm.user.manageRequests ||
            (_vm.user.role == 'client' &&
              _vm.$store.getters['request/activeRequests'].length <
                _vm.checkAvailableRequest())
          )?_c('v-btn',{attrs:{"to":{ name: 'SelectRequestType' },"color":"white primary--text","small":""}},[_vm._v("Create A Request")]):_vm._e()]},proxy:true}],null,false,2173755577)}),_c('v-alert',{attrs:{"value":_vm.user.role === 'client' &&
        _vm.$store.getters['request/activeRequests'].length >=
          _vm.checkAvailableRequest(),"type":"warning","border":"left","text":""}},[_vm._v(" You have reached the maximum number of active requests for your package. Check your active requests, mark pending requests as completed or pause some requests so you can submit more requests ")]),(_vm.user)?_c('v-tabs',{staticClass:"request-tabs",attrs:{"color":"primary","slider-color":"primary"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{attrs:{"to":{ name: 'OpenRequests' },"exact":""}},[_c('span',{staticClass:"slider-title"},[_vm._v("In Progress")])]),(_vm.user.role !== 'client')?_c('v-tab',{attrs:{"to":{ name: 'PendingReviewRequests' },"exact":""}},[_c('span',{staticClass:"slider-title"},[_vm._v("Pending Client Review")])]):_vm._e(),_c('v-tab',{attrs:{"to":{ name: 'CompletedRequests' }}},[_c('span',{staticClass:"slider-title"},[_vm._v("Completed")])]),(
          _vm.user &&
          (_vm.user.role == 'admin' ||
            (_vm.user.manageRequests && _vm.user.manageRequestForm))
        )?_c('v-tab',{attrs:{"to":{ name: 'RequestTypes' }}},[_c('span',{staticClass:"slider-title"},[_vm._v("Forms")])]):_vm._e(),(
          _vm.user &&
          (_vm.user.role == 'admin' ||
            (_vm.user.manageRequests && _vm.user.manageRequestPackages))
        )?_c('v-tab',{attrs:{"to":{ name: 'RequestPackages' }}},[_c('span',{staticClass:"slider-title"},[_vm._v("Packages")])]):_vm._e(),(
          _vm.user &&
          (_vm.user.role == 'admin' ||
            (_vm.user.manageRequests && _vm.user.manageRequestCannedResponses))
        )?_c('v-tab',{attrs:{"to":{ name: 'RequestCannedResponses' }}},[_c('span',{staticClass:"slider-title"},[_vm._v("Canned Responses")])]):_vm._e()],1):_vm._e(),_c('router-view'),_c('create',{attrs:{"show":_vm.createDialog},on:{"close":_vm.closeDialog}})]:(_vm.user)?_c('v-alert',{attrs:{"type":"info","border":"left"}},[_vm._v(" You must be an existing client or had previous orders to access request feature. ")]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }